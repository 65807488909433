import InputLabel from "@material-ui/core/InputLabel";
import { useFormik } from "formik";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import BootstrapInput from "../../components/BootstrapInput";
import { requestPassword } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";
import LogoHeader from './LogoHeader';

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      requestPassword(values.email)
        .then(() => setIsRequested(true))
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );
        });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div 
        style={{backgroundColor: "#E5E5E5", height: "100%", display: "block"}}
        className="login-form login-forgot"
        >
          <LogoHeader />
          <form 
            style={{padding: "8%", marginTop: "27%"}}
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
            <InputLabel  htmlFor="email">
            <strong>E-mail Address</strong>
          </InputLabel>
          <BootstrapInput
            type="email"
            name="email"
            className={`form-control form-control-solid h-auto py-0 px-0 ${getInputClasses(
              "email"
            )}`}
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
            
              {/* <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null} */}
            </div>
            <div className="kt-login__actions"
                  
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    marginTop: "25px"
                  }}
                >
                  <Link to="/auth">
                      Back
                  </Link>

                  <button
                    style={{
                      backgroundColor: "#55B0FF",
                      borderRadius: "6px",
                      marginTop: "8px",
                      height: "45px",
                      
                      
                    }}
                    type="submit"
                    className="btn btn-elevate kt-login__btn-primary"
                    disabled={formik.isSubmitting}
                  ><b style={{color: "black"}}>
                    RECEIVE A NEW PASSWORD

                  </b>
                  </button>
                </div>
            {/* <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div> */}
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
