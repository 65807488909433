import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import SnackBar from "./globalUI/snackBar/SnackBar";

import { BuilderPage } from "./pages/BuilderPage";

import { HomePage } from "./pages/Home";
//import {DashboardPage} from "./pages/DashboardPage";

/* const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
); */

const AdminPage = lazy(() => import("./modules/Admin/AdminPage"));

const Sms = lazy(() => import("./modules/Sms/SmsPage"));

const ReportingPage = lazy(() => import("./modules/Reporting/ReportingPage"));

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <SnackBar />
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/home" />
                }
                <ContentRoute path="/builder" component={BuilderPage} />
                <ContentRoute path="/home" component={HomePage} />

                <Route path="/admin" component={AdminPage} />
                <Route path="/sms" component={Sms} />
                <Route path="/reporting" component={ReportingPage} />

                {/* <Route path="/home" component={GoogleMaterialPage}/> */}
                {/* <Route path="/react-bootstrap" component={ReactBootstrapPage}/> */}
                <Redirect to="error/error-v1" />
            </Switch>
        </Suspense>
    );
}
