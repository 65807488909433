import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { schoolSlice } from "../app/modules/Tables/_redux/school/schoolSlice";
import { professorSlice } from "../app/modules/Tables/_redux/professor/professorSlice";
import { studentSlice } from "../app/modules/Tables/_redux/student/studentSlice";
import { uiReducer } from "../app/globalUI/snackBar.duck";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    schools: schoolSlice.reducer,
    professors: professorSlice.reducer,
    students: studentSlice.reducer,
    ui: uiReducer,
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
