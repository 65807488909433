import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import config from "./config";
import { Fuego } from "@nandorojo/swr-firestore";

export const firebaseApp = firebase.initializeApp(config);
export const firestore = firebase.firestore();
export const auth = firebaseApp.auth();
export const storage = firebase.storage();
export const functions = firebase.app().functions("europe-west2");

// export const fuego = new Fuego(config);

export const createUserProfileDocument = async (userAuth, additionalData) => {
    if (!userAuth) return;

    const userRef = firestore.doc(`Login/${userAuth.uid}`);

    const snapShot = await userRef.get();

    if (!snapShot.exists) {
        const { displayName, email } = userAuth;
        const createdAt = new Date();
        try {
            await userRef.set({
                displayName,
                email,
                createdAt,
                ...additionalData,
            });
        } catch (error) {
            console.log("error creating user", error.message);
        }
    }

    return userRef;
};

export const addDocToCollection = async (collectionKey, data) => {
    const docRef = firestore.collection(collectionKey).doc();
    return await docRef.set({ ...data });
};

//custum claims
export const getUserClaims = async (uid) => {
    const userClaimsRef = firestore.doc(`user-claims/${uid}`);
    let userClaims;
    try {
        await userClaimsRef.get().then((doc) => {
            doc.exists ? (userClaims = doc.data()) : (userClaims = undefined);
        });
    } catch (err) {
        console.log("Error while fetching custom claims: ", err);
    }

    console.log(userClaims);

    return userClaims;
};

export async function createUser(user) {
    console.log("Data: " + user);

    const createUser = firebase.functions().httpsCallable("createUser");

    /*createUser(data).then(result => {
    console.log(result)
  })

  return data*/

    const { data } = await createUser(user);

    if (!data.uid) {
        throw Error(data.errorInfo?.message);
    }

    /*const customerClaims = customer.customer
    ? { [customer.profile]: true, customer: customer.customer }
    : { [customer.profile]: true }*/

    const customerClaims = { [user.admins]: true };

    await firestore.doc(`user-claims/${data.uid}`).set(customerClaims);

    return { ...data, customClaims: { ...customerClaims } };
}

export const convertCollectionSnapshotToMap = (collection, field) => {
    const transformedCollection = collection.docs.map((doc) => {
        const { title, items } = doc.data();

        return {
            routeName: encodeURI(title.toLowerCase()),
            id: doc.id,
            title,
            items,
        };
    });

    // TODO: must add doc field manualy for now on Accumulator
    return transformedCollection.reduce((accumulator, doc) => {
        accumulator[doc[field]] = doc;
        return accumulator;
    }, {});
};
