import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  popper: {
    margin: "-40px",
    maxWidth: "330px"
  },
  children: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 5px 4px 0px"
  },
  title: {
    color: "red"
  }
}));

export default function SimplePopper({anchorEl, open, message, children, title, setOpen}) {

  const classes = useStyles();
 
  const id = open ? 'simple-popper' : undefined;

  return (
    <div>
    
      <Popper placement={"top"} id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.popper}>
             <div className={classes.typography}>
               <h5 className={classes.title}>{title}</h5>
               {message}
               </div>
               <div className={classes.children}>
               {children}
               </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
