import { 
  auth, 
  addDocToCollection, 
  firestore,
  firebaseApp as firebase
} from "../../../../firebase";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me"; 

export function login(email, password) {
  return auth
    .signInWithEmailAndPassword(email, password)
    .catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
      console.log("error login:", errorCode, errorMessage);
    });

  /*   return axios.post(LOGIN_URL, { email, password }); */
}

export function register(values) {
  return addDocToCollection("Contact", [{ ...values }]);
  /*   return axios.post(REGISTER_URL, { email, fullname, username, password }); */
}

export function signOut(){
  return auth.signOut();
}

export function requestPassword(email) {
  return auth.sendPasswordResetEmail(email);
}

export function getCurrentUser() {
  // Authorization head should be fulfilled in interceptor.
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
}

export const getUserClaims = async (uid) => {
  const userClaimsRef = firestore.doc(`user-claims/${uid}`);
  let userClaims;
  try{

    await userClaimsRef.get().then(doc => {
      doc.exists ? userClaims = doc.data() 
        : userClaims = undefined;
    })

  } catch(err) {
    console.log("Error while fetching custom claims: ", err)
  }

  console.log(userClaims)

  return userClaims;
}

// get all info inside user-claims
export async function getUserWithClaims(uid) {
  const getUserById = firebase.functions().httpsCallable("getUserByID");

  return await getUserById(uid);
}