/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import CommentIcon from "@material-ui/icons/Comment";
import ComputerIcon from "@material-ui/icons/Computer";
import HomeIcon from "@material-ui/icons/Home";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url)
            ? " menu-item-active menu-item-open "
            : "";
    };

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                <li
                    className={`menu-item ${getMenuItemActive("/home")}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/home">
                        <span className="svg-icon menu-icon">
                            <HomeIcon />
                        </span>
                        <span className="menu-text">Home</span>
                    </NavLink>
                </li>

                {/* Begin Admin */}

                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/admin"
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <NavLink className="menu-link menu-toggle" to="/admin">
                        <span className="svg-icon menu-icon">
                            <ComputerIcon />
                        </span>
                        <span className="menu-text">Admin</span>
                        <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu ">
                        <ul className="menu-subnav">
                            <ul className="menu-subnav">
                                <li
                                    className="menu-item  menu-item-parent"
                                    aria-haspopup="true"
                                >
                                    <span className="menu-link">
                                        <span className="menu-text">Admin</span>
                                    </span>
                                </li>

                                <li
                                    className={`menu-item ${getMenuItemActive(
                                        "/admin/region"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink
                                        className="menu-link"
                                        to="/admin/region"
                                    >
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text">
                                            Distrito
                                        </span>
                                    </NavLink>
                                </li>

                                <li
                                    className={`menu-item ${getMenuItemActive(
                                        "/admin/school"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink
                                        className="menu-link"
                                        to="/admin/school"
                                    >
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text">
                                            Escola
                                        </span>
                                    </NavLink>
                                </li>

                                <li
                                    className={`menu-item ${getMenuItemActive(
                                        "/admin/class"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink
                                        className="menu-link"
                                        to="/admin/class"
                                    >
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text">
                                            Classe
                                        </span>
                                    </NavLink>
                                </li>

                                <li
                                    className={`menu-item ${getMenuItemActive(
                                        "/admin/student"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink
                                        className="menu-link"
                                        to="/admin/student"
                                    >
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text">
                                            Aluno/a
                                        </span>
                                    </NavLink>
                                </li>

                                <li
                                    className={`menu-item ${getMenuItemActive(
                                        "/admin/professor"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink
                                        className="menu-link"
                                        to="/admin/professor"
                                    >
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text">
                                            Professor/a
                                        </span>
                                    </NavLink>
                                </li>

                                <li
                                    className={`menu-item ${getMenuItemActive(
                                        "/admin/others"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink
                                        className="menu-link"
                                        to="/admin/others"
                                    >
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text">
                                            Outros
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </ul>
                    </div>
                </li>

                {/* End Admin */}

                {/* Begin Sms */}

                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/sms"
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <NavLink className="menu-link menu-toggle" to="/sms">
                        <span className="svg-icon menu-icon">
                            <CommentIcon />
                        </span>
                        <span className="menu-text">Sms</span>
                        <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu ">
                        <ul className="menu-subnav">
                            <ul className="menu-subnav">
                                <li
                                    className="menu-item  menu-item-parent"
                                    aria-haspopup="true"
                                >
                                    <span className="menu-link">
                                        <span className="menu-text">Sms</span>
                                    </span>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive(
                                        "/sms/templates"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink
                                        className="menu-link"
                                        to="/sms/templates"
                                    >
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text">
                                            Templates
                                        </span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive(
                                        "/sms/send"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink
                                        className="menu-link"
                                        to="/sms/send"
                                    >
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text">
                                            Enviar SMS
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </ul>
                    </div>
                </li>

                {/* End Sms */}

                {/* Begin Reporting */}

                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/reporting"
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <NavLink className="menu-link menu-toggle" to="/reporting">
                        <span className="svg-icon menu-icon">
                            <InsertChartIcon />
                        </span>
                        <span className="menu-text">Reporting</span>
                        <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu ">
                        <ul className="menu-subnav">
                            <ul className="menu-subnav">
                                <li
                                    className="menu-item  menu-item-parent"
                                    aria-haspopup="true"
                                >
                                    <span className="menu-link">
                                        <span className="menu-text">
                                            Reporting
                                        </span>
                                    </span>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive(
                                        "/reporting/status-sms"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink
                                        className="menu-link"
                                        to="/reporting/status-sms"
                                    >
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text">
                                            Status do SMS
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </ul>
                    </div>
                </li>
            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
