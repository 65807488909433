const config = {
  apiKey: "AIzaSyDC6C77Xej20DdJb8PgmXhAjWHC2vRfNdA",
  authDomain: "apricity-africa.firebaseapp.com",
  databaseURL: "https://apricity-africa.firebaseio.com",
  projectId: "apricity-africa",
  storageBucket: "apricity-africa.appspot.com",
  messagingSenderId: "553925469054",
  appId: "1:553925469054:web:a3fe12e4d4e5b64127892f",
  measurementId: "G-K9TS93XPMY"
};

export default config;
