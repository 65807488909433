import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    carForEdit: undefined,
    lastError: null,
    regions: undefined,
};
export const callTypes = {
    list: "list",
    action: "action",
};

export const schoolSlice = createSlice({
    name: "schools",
    initialState: initialProductsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getProductById
        carFetched: (state, action) => {
            state.actionsLoading = false;
            state.carForEdit = action.payload.carForEdit;
            state.error = null;
        },
        regionsFetched: (state, action) => {
            state.actionsLoading = false;
            state.regions = action.payload.regions;
            state.error = null;
        },
        // findProducts
        carsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // createProduct
        carCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.car);
        },
        carsCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            action.payload.cars.forEach((car) => {
                state.entities.push(car);
            });
        },
        // updateProduct
        carUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map((entity) => {
                if (entity.schoolId === action.payload.car.schoolId) {
                    return action.payload.car;
                }
                return entity;
            });
        },
        // deleteProduct
        carDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                (el) => el.schoolId != action.payload.id
            );
        },

        carsDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                (el) => !action.payload.ids.includes(el.id)
            );
        },
        // productsUpdateState
        /*     productsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    } */
    },
});
