/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import objectPath from "object-path";
import React, { useMemo, useState } from "react";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useHtmlClassService } from "../../../_core/MetronicLayout";

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};

export function UserNotificationsDropdown() {
    const [key, setKey] = useState("Alerts");
    const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            offcanvas:
                objectPath.get(
                    uiService.config,
                    "extras.notifications.layout"
                ) === "offcanvas",
        };
    }, [uiService]);

    return <></>;
}
