import objectPath from "object-path";
import React, { useEffect, useMemo, useState } from "react";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { firestore as db } from "../../../../firebase";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";



export function Brand() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
          uiService.config,
          "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  // custom-claims getting customerId
  const [customerName, setCustomerName] = useState("")
  const customerId = useSelector((state) => state.auth.claims.customerId)

  useEffect(() => {
    const customerName = db.collection('Customers').doc(customerId)
    customerName.get().then(doc => {
      setCustomerName(doc.data().name)
    })
  }, [])

  return (
    <>
      {/* begin::Brand */}
      <div
          className={`brand flex-column-auto ${layoutProps.brandClasses}`}
          id="kt_brand"
      >
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
        <h2 className="customerName">{customerName}</h2>
        </Link>
        {/* end::Logo */}      

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
              </span>
            </button>
            {/* end::Toolbar */}
            </>
        )}
      </div>
      {/* end::Brand */}
      </>
  );
}
