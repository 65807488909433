import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import { useFormik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import BootstrapInput from "../../components/BootstrapInput";
import { register } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";
import terms from './contactTerms';
import LogoHeader from "./LogoHeader";
import Popper from './Popover';

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  message: "",
  acceptTerms: false,
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPoper, setPoper] = useState(false)


  const RegistrationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    message: Yup.string()
      .min(6, "Minimum 6 symbols")
      .max(100, "Maximum 100 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),

    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
/*
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  message: "",
  acceptTerms: false,
*/
  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log(values);
      enableLoading();
      register({
        firstname: values.firstName, 
        lastname: values.lastName, 
        phone: values.phone, 
        email: values.email, 
        message: values.message})
        .then(() => {
          disableLoading();
          setStatus(intl.formatMessage({id: "AUTH.GENERAL.CONTACT"}))
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
          disableLoading();
        });
    },
  });

  return (
    <div
      className="login-form login-signin"
      style={{ display: "block", backgroundColor: "#E5E5E5", height: "100%" }}
    >
      <LogoHeader />
      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
        style={{ padding: "8%", marginTop: "2%" }}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: firstName*/}
        <div className="form-group fv-plugins-icon-container">
          <InputLabel  htmlFor="firstName">
            <strong>Firt Name</strong>
          </InputLabel>
          <BootstrapInput
            type="text"
            name="firstName"
            className={`form-control form-control-solid h-auto py-0 px-0 ${getInputClasses(
              "firstName"
            )}`}
            {...formik.getFieldProps("firstName")}
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.firstName}</div>
            </div>
          ) : null}
        </div> 
        {/* end: firstName */}

        {/* begin: last name */}
        <div className="form-group fv-plugins-icon-container">
          <InputLabel  htmlFor="lastName">
            <strong>Last Name</strong>
          </InputLabel>
          <BootstrapInput
            type="text"
            name="lastName"
            className={`form-control form-control-solid h-auto py-0 px-0 ${getInputClasses(
              "lastName"
            )}`}
            {...formik.getFieldProps("lastName")}
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.lastName}</div>
            </div>
          ) : null}
        </div> 
        {/* end: last name */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <InputLabel  htmlFor="email">
            <strong>E-mail Address</strong>
          </InputLabel>
          <BootstrapInput
            type="email"
            name="email"
            className={`form-control form-control-solid h-auto py-0 px-0 ${getInputClasses(
              "email"
            )}`}
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div> 
        {/* end: Email */}

        {/* begin: phone number */}
        <div className="form-group fv-plugins-icon-container">
          <InputLabel  htmlFor="phone">
            <strong>Phone Number</strong>
          </InputLabel>
          <MuiPhoneNumber 
           name="phone" 
           defaultCountry={"pt"}
           className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
            "phone"
          )}`}
          {...formik.getFieldProps("phone")}
          onChange={(e) => formik.setFieldValue("phone", e)}
          />
           {formik.touched.phone && formik.errors.phone ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phone}</div>
            </div>
          ) : null}
        </div>
        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <InputLabel  htmlFor="message">
            <strong>Message</strong>
          </InputLabel>
          <BootstrapInput
            type="text"
            name="message"
            multiline
            rows={3}
            className={`form-control form-control-solid h-auto py-0 px-0 ${getInputClasses(
              "message"
            )}`}
            {...formik.getFieldProps("message")}
          />
          {formik.touched.message && formik.errors.message ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.message}</div>
            </div>
          ) : null}
        </div>

      

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              {...formik.getFieldProps("acceptTerms")}
            />
            I agree the{" "}
            <Link 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={e => {
                e.preventDefault();
                setAnchorEl(e.currentTarget);
                setPoper(true)
              }}  
            >
              Terms & Conditions
            </Link>
            .
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                }}
                className="kt-login__actions"
              >
                <Link to="/auth">Back</Link>
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#55B0FF",
                    marginTop: "8px",
                    height: "36px",
                  }}
                  disabled={formik.isSubmitting || !formik.values.acceptTerms}
                  className="btn btn-primary btn-elevate kt-login__btn-primary"
                >
                 <strong style={{color:"black"}}>
                   Send
                   </strong> 
                </button>
              </div>
      
              <Popper
                title="Terms"
                open={openPoper}
                anchorEl={anchorEl}
                message={terms.message}
                children={
                  <>
                    <Button onClick={() => setPoper(false)} color="primary">
                      Close
                    </Button>
                  </>
                }
              />
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
