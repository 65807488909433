import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default function LogoHeader() {
    return (
        <div
            style={{
                marginTop: "40px",
                width: "100%",
            }}
            className="kt-login__title"
        >
            <img
                style={{ width: "100%" }}
                alt="Logo"
                src={toAbsoluteUrl("/media/helpo/helpo.JPG")}
            />
        </div>
    );
}
