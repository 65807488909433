import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { login } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";
import LogoHeader from './LogoHeader';
import Popper from './Popover';
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openPoper, setPoper] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then((user) => {
            disableLoading();
            //console.log(user.user.uid)
            props.login(user.user.uid);
          })
          .catch(() => {
            disableLoading();
            setPoper(true)
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 600);
    },
  });

  return (
    <div style={{backgroundColor: "#E5E5E5", height: "100%"}} className="login-form login-signin" id="kt_login_signin_form">

     
      <LogoHeader />
      {/*begin::Form*/}
      <form style={{padding: "8%", marginTop: "10%"}}
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
       

        <div  className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <Box display="flex" flexDirection="column" style={{marginTop: "10%"}}>
          
          <button
            id="kt_login_signin_submit"
            type="submit"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            disabled={formik.isSubmitting}
            className={`btn font-weight-bold px-9 py-4 my-3`}
            style={{backgroundColor: "#55B0FF"}}
          >
            <span style={{color: "black"}}>
              <strong>
                Log In
              </strong>
              </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <Link to="/auth/registration">
                    <h6 style={{
                      color: "black",
                      marginTop: "5%",
                      
                    }}>No Account ?</h6>
                    <button
                      id="kt_login_signin_submit"
                      className={`btn font-weight-bold px-9 py-4 my-3`}
                      style={{backgroundColor: "#55B0FF", width: "100%"}}
                    >
                      <strong style={{color: "black"}}>Contact</strong>
                    </button>
            </Link>
        </Box>
      </form>
      {/*end::Form*/}
      {/* Log in Fail Pop Up */}
      <Popper
            title="Log In Failed"
            open={openPoper}
            anchorEl={anchorEl}
            message="You entered an incorrect email or password. Do you want to reset your password?"
            children={
              <>
                <Button onClick={() => setPoper(false)} >
                  Cancel
                </Button>
                <Link to="/auth/forgot-password">
                  <Button color="secondary">Reset</Button>
                </Link>
              </>
            }
          />
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
