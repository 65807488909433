import { persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { 
  signOut, 
  getCurrentUser, 
  getUserClaims, getUserWithClaims 
} from "./authCrud";
import JSOG from 'jsog';
//import { getUserClaims } from '../../../../firebase'

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  getUserClaims: "GET_USER_CLAIMS"
};

const initialAuthState = {
  user: undefined,
  claims: undefined,
  authToken: undefined
};

export const JSOGTransform = createTransform(
  (inboundState, key) => JSOG.encode(inboundState),
  (outboundState, key) => JSOG.decode(outboundState),
)

export const reducer = persistReducer(
  { storage, key: "auth.uride", transforms: [JSOGTransform], whitelist: [ "user", "claims", "authToken", ] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        console.log({user})
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.getUserClaims: {
        //console.log("type: getUserClaims, payload: ", action.payload)
        const  { claims } = action.payload;
        return { ...state, claims}
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  signInFailure: (error) => ({
    type: actionTypes.SIGN_IN_FAILURE,
    payload: error,
  }),
  setUserClaims: claims => ({ type: actionTypes.getUserClaims, payload: { claims }})
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, userRequested);

  yield takeLatest(actionTypes.Logout, logoutSaga);
}


function* logoutSaga() {
  try {
    yield signOut()
    console.log("logout success")
  } catch (error) {
    //yield put(signOutFailure(error));
    console.log("sign out fail", error)
  }
}


function* userRequested() {
  const user = yield getCurrentUser();
  //.log(user)
  const {
    email,
    displayName,
    phoneNumber,
    emailVerified,
    uid,
    metadata,
    photoURL,
    refreshToken,
  } = user;


  const userData = {
    username: displayName,
    email,
    phone: phoneNumber,
    pic: photoURL,
    accessToken: uid,
    refreshToken,
    metadata,
    emailVerified,
    id: uid,
    fullname: displayName,
    user: {...user}
    
  };

  // Show custum claims 
  // const userWithClaims = yield getUserWithClaims({uid})
  // yield console.log("current User",{userWithClaims, uid})

  const userClaims = yield getUserClaims(uid);
  //console.log("user claims: ", userClaims)
  
  yield put(actions.setUserClaims(userClaims))
  yield put(actions.fulfillUser(userData));
  //yield createUserProfileDocument(user, { uid, role: "Admin", photoURL });
}



/* 
  firebase.auth().currentUser.getIdTokenResult()
  .then((idTokenResult) => {
    // Confirm the user is an Admin 
    if(idTokenResult.claims.moderator) {
      showModeratorUI();
    } else {
      // Show regular user UI.
      showRegularUI();
    }
  })
  .catch(err => console.log(err))
*/