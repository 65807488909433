export const actionTypes = {
  openSnackBarSuccess: "SNACKBAR_SUCCESS",
  openSnackBarInfo: "SNACKBAR_INFO",
  openSnackBarError: "SNACKBAR_ERROR",
  openSnackBarWarning: "SNACKBAR_WARNING",
  closeSnackBar: "SNACKBAR_CLEAR",
};

const initialState = {
  successSnackbarOpen: false,
  typeOfSnackbar: "",
  successSnackBarMessage: undefined,
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.openSnackBarSuccess:
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.payload,
        typeOfSnackbar: "success",
      };
    case actionTypes.openSnackBarInfo:
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.payload,
        typeOfSnackbar: "info",
      };
    case actionTypes.openSnackBarError:
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.payload,
        typeOfSnackbar: "error",
      };
    case actionTypes.openSnackBarWarning:
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.payload,
        typeOfSnackbar: "warning",
      };
    case actionTypes.closeSnackBar:
      return {
        ...state,
        successSnackbarOpen: false,
      };
    default:
      return state;
  }
};

export const actions = {
  showSuccessSnackBar: (message) => ({
    type: actionTypes.openSnackBarSuccess,
    payload: { message },
  }),
  clearSnackBar: () => ({ type: actionTypes.closeSnackBar }),
};
