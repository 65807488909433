import React, { useEffect, useState } from "react";

//import Button from "@material-ui/core/Button";
//import {appAdmin as admin} from '../../firebase/admin';
import { useSelector } from "react-redux";
import { Carousel } from "react-bootstrap";
import Chart from "react-apexcharts";
import { firestore as db } from "../../firebase";
import home from "./home.png";
import "./styles.css";


require("dotenv").config();

export const HomePage = () => {
    // custom-claims getting customerId
    const [customerName, setCustomerName] = useState("");
    const [description, setDescription] = useState("");
    const [logo, setLogo] = useState("");
    const customerId = useSelector((state) => state.auth.claims.customerId);

    const [series, setSeries] = useState([0, 0]);
    
    useEffect(() => {
        const customerName = db.collection("Customers").doc(customerId);
        customerName.get().then((doc) => {
            setCustomerName(doc.data().name);
            setDescription(doc.data().description);
            setLogo(doc.data().sponsors);
        });
    }, []);

    const customerSMS = useSelector((state) => state.auth.claims.customerId)

    useEffect( () => {
      db.collection("smsLogs")
      .where("customerId", "==", customerSMS)
        .get()
        .then(function(querySnapshot) {
          var smsDelivered = 0
          var smsUndelivered = 0
          var smsSent = 0
          var smsFailed = 0
          querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            if(doc.data().status == "delivered" ) {
              smsDelivered = smsDelivered + 1 
            } else if (doc.data().status == "queued" ) {
              smsSent = smsSent + 1
           } else if (doc.data().status == "undelivered" ) {
             smsUndelivered = smsUndelivered + 1
            } else if (doc.data().status == "failed" ) {
             smsFailed = smsFailed + 1
            }
          });
         // console.log("aqui" , smsDelivered, smsSent, smsUndelivered, smsFailed)
         // console.log("aqui", options)
          setSeries([smsDelivered , smsSent, smsUndelivered, smsFailed])
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    
    },[]);

    var options = {
      series: series,
      options: {
        chart: {
          width: 380,
          type: "donut",
        },
        labels: ["delivered", "queued", "undelivered", "failed"],
      },
    }; 


    useEffect( () => {
      db.collection("smsLogs")
      .where("customerId", "==", customerSMS)
        .get()
        .then(function(querySnapshot) {
          var smsDelivered = 0
          var smsUndelivered = 0
          var smsSent = 0
          var smsFailed = 0
          querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            if(doc.data().status == "delivered" ) {
              smsDelivered = smsDelivered + 1 
            } else if (doc.data().status == "queued" ) {
              smsSent = smsSent + 1
           } else if (doc.data().status == "undelivered" ) {
             smsUndelivered = smsUndelivered + 1
            } else if (doc.data().status == "failed" ) {
             smsFailed = smsFailed + 1
            }
          });
         // console.log("aqui" , smsDelivered, smsSent, smsUndelivered, smsFailed)
         // console.log("aqui", options)
          setSeries([smsDelivered , smsSent, smsUndelivered, smsFailed])
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    
    },[]);

    var options = {
      series: series,
      options: { 
        chart: {
          width: 380,
          type: "donut",
        },
        labels: ["delivered", "queued", "undelivered", "failed"],
        plotOptions: {
        pie: {
          donut: {
            labels: { 
              show: true,
              total: {show: true, 
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                  }, 0)
                }
              }
              
            }
          }
        }
      }
        
      }
    };



    return (
        <div className="main">
            <img className="home-img" src={home} />
            <div className="text">
                <h3>
                    Bem vindo ao projeto{" "}
                    <span className="customerName">{customerName}</span>
                </h3>
                <br />
                <p>{description}</p>
            </div>
             <div className="row" style={{ width: "100%"}}>
               
            <div className="col-4">
                <Carousel indicators={false} controls={false} style={{ height: "100px", width: "200px"}}>
                    {logo !== ""
                        ? logo.map((obj, key) => (
                              <Carousel.Item key={key} interval={1000}>
                                  <img
                                      
                                      className="d-block w-100"
                                      src={obj.url}
                                  />
                              </Carousel.Item>
                          ))
                        : null}
                </Carousel>
            </div>
              
              <div className="col-8 align-self-end" >         
          <Chart 
                    options={options.options}
                    series={series}
                    type="donut"
                    height={200}
                /> 
                </div> 
                 </div> 
            </div>            
    );
};
